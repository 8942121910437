import { FC } from 'react';

const WalletIcon: FC<{ height?: string; width?: string }> = ({
	height = 157,
	width = 117,
}) => {
	return (
		<svg
			aria-labelledby="wallet-4steps"
			width={width}
			height={height}
			viewBox="0 0 157 117"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="wallet-4steps" lang="ja">
				財布のイメージ
			</title>

			<g clipPath="url(#clip0_0_8392)">
				<path
					d="M149.952 17.2148H54.0999C53.3812 17.2146 52.6891 17.4863 52.1626 17.9753C51.6735 18.3323 47.8656 21.1794 47.5453 23.5271C47.203 26.0363 50.5187 23.9528 50.5187 23.9528L51.2419 23.4318V82.9333C51.2419 83.6909 51.543 84.4174 52.079 84.9531C52.615 85.4888 53.3419 85.7897 54.0999 85.7897H149.952C150.328 85.7897 150.699 85.7158 151.046 85.5723C151.393 85.4288 151.708 85.2184 151.973 84.9531C152.239 84.6879 152.449 84.373 152.593 84.0264C152.736 83.6799 152.81 83.3084 152.81 82.9333V20.0713C152.81 19.6962 152.736 19.3247 152.593 18.9782C152.449 18.6316 152.239 18.3167 151.973 18.0515C151.708 17.7862 151.393 17.5758 151.046 17.4323C150.699 17.2887 150.328 17.2148 149.952 17.2148Z"
					fill="#5840FF"
				/>
				<path
					d="M141.183 68.0433L142.68 24.4863C142.7 23.9055 142.606 23.3264 142.402 22.7821C142.198 22.2378 141.889 21.739 141.492 21.3141C141.095 20.8892 140.618 20.5466 140.089 20.3058C139.56 20.065 138.988 19.9307 138.407 19.9107L62.3861 17.2862C61.2125 17.2457 60.0709 17.6728 59.2126 18.4736C58.3542 19.2744 57.8493 20.3832 57.809 21.5562L56.3112 65.1132C56.2913 65.694 56.3859 66.2731 56.5899 66.8173C56.7938 67.3616 57.103 67.8604 57.4998 68.2853C57.8965 68.7102 58.3732 69.0528 58.9024 69.2936C59.4316 69.5344 60.0031 69.6687 60.5842 69.6887L136.605 72.3133C137.186 72.3333 137.766 72.2388 138.31 72.0351C138.855 71.8313 139.354 71.5224 139.779 71.1259C140.204 70.7294 140.547 70.2531 140.788 69.7242C141.028 69.1952 141.163 68.6241 141.183 68.0433Z"
					fill="#10C395"
				/>
				<path
					d="M143.106 20.8713L67.053 19.4758C64.608 19.4309 62.5893 21.3755 62.5441 23.8192L61.7383 67.3944C61.6931 69.8381 63.6385 71.8555 66.0834 71.9003L142.137 73.2958C144.582 73.3407 146.601 71.3961 146.646 68.9524L147.452 25.3772C147.497 22.9335 145.551 20.9162 143.106 20.8713Z"
					fill="#A4FFE8"
				/>
				<path
					d="M86.6839 25.2125L71.8389 23.2285L70.1787 35.6376L85.0237 37.6215L86.6839 25.2125Z"
					fill="#F0FFFF"
				/>
				<path
					d="M146.203 41.8452L76.2031 32.4902L75.3522 38.8504L145.352 48.2054L146.203 41.8452Z"
					fill="#F0FFFF"
				/>
				<path
					d="M93.2071 44.2913L74.9512 41.8516L74.7025 43.7102L92.9584 46.15L93.2071 44.2913Z"
					fill="#F0FFFF"
				/>
				<path
					d="M144.903 51.1923L98.3643 44.9746L98.1157 46.8333L144.654 53.0509L144.903 51.1923Z"
					fill="#F0FFFF"
				/>
				<path
					d="M124.863 41.0052C127.845 41.0052 130.262 38.5892 130.262 35.609C130.262 32.6288 127.845 30.2129 124.863 30.2129C121.881 30.2129 119.464 32.6288 119.464 35.609C119.464 38.5892 121.881 41.0052 124.863 41.0052Z"
					fill="#00C8F8"
				/>
				<path
					d="M137.708 37.3255C137.518 38.7441 136.772 40.0292 135.634 40.8982C134.496 41.7673 133.059 42.1491 131.64 41.9599C130.938 41.8669 130.261 41.6363 129.648 41.2814C129.036 40.9265 128.499 40.4542 128.07 39.8916C127.638 39.3284 127.321 38.6854 127.138 37.9996C126.956 37.3138 126.91 36.5987 127.005 35.8953C127.192 34.4893 127.926 33.2137 129.048 32.3453C129.613 31.9071 130.26 31.5857 130.951 31.3999C131.641 31.2141 132.362 31.1676 133.071 31.2631C134.49 31.4527 135.776 32.1978 136.645 33.3347C137.515 34.4716 137.897 35.9071 137.708 37.3255Z"
					fill="#5840FF"
				/>
				<path
					d="M146.085 21.5234H50.232C48.6536 21.5234 47.374 22.8023 47.374 24.3799V87.2421C47.374 88.8197 48.6536 90.0985 50.232 90.0985H146.085C147.663 90.0985 148.943 88.8197 148.943 87.2421V24.3799C148.943 22.8023 147.663 21.5234 146.085 21.5234Z"
					fill="#6675F6"
				/>
				<path
					d="M144.202 25.6328H51.2695V85.9346H143.999L144.202 25.6328Z"
					stroke="#949FFF"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeDasharray="9 9"
				/>
				<path
					opacity="0.3"
					d="M152.8 45.6113C152.8 45.6113 153.045 50.9526 147.605 51.0648L142.007 51.1334H125.311C123.998 51.1334 122.74 51.6545 121.812 52.5819C120.884 53.5094 120.362 54.7674 120.362 56.0791C120.362 57.3907 120.884 58.6487 121.812 59.5762C122.74 60.5037 123.998 61.0247 125.311 61.0247H142.007C142.007 61.0247 150.763 61.5791 152.645 58.6607C155.374 54.4288 152.8 45.6113 152.8 45.6113Z"
					fill="#2D19BE"
				/>
				<path
					opacity="0.3"
					d="M152.8 47.2051C152.8 47.2051 153.045 52.5464 147.605 52.6585L142.007 52.7272H125.311C123.998 52.7272 122.74 53.2482 121.812 54.1757C120.884 55.1032 120.362 56.3612 120.362 57.6728C120.362 58.9845 120.884 60.2425 121.812 61.17C122.74 62.0974 123.998 62.6185 125.311 62.6185H142.007C142.007 62.6185 150.763 63.1729 152.645 60.2545C155.374 56.0225 152.8 47.2051 152.8 47.2051Z"
					fill="#2D19BE"
				/>
				<path
					d="M152.8 46.4082C152.8 46.4082 153.045 51.7495 147.605 51.8616L142.007 51.9303H125.311C123.998 51.9303 122.74 52.4513 121.812 53.3788C120.884 54.3063 120.362 55.5643 120.362 56.876C120.362 58.1876 120.884 59.4456 121.812 60.3731C122.74 61.3006 123.998 61.8216 125.311 61.8216H142.007C142.007 61.8216 150.763 62.376 152.645 59.4576C155.374 55.2257 152.8 46.4082 152.8 46.4082Z"
					fill="#6675F6"
				/>
				<path
					d="M126.91 60.3061C128.745 60.3061 130.234 58.8188 130.234 56.9841C130.234 55.1494 128.745 53.6621 126.91 53.6621C125.074 53.6621 123.586 55.1494 123.586 56.9841C123.586 58.8188 125.074 60.3061 126.91 60.3061Z"
					fill="#FFA26B"
				/>
			</g>
			<g clipPath="url(#clip1_0_8392)">
				<path
					d="M93.5569 47.557L29.2304 29.0266C25.6034 27.9818 21.8161 30.075 20.7713 33.7021L9.91588 71.3854C8.87106 75.0124 10.9643 78.7997 14.5913 79.8445L78.9179 98.3749C82.5449 99.4198 86.3321 97.3265 87.377 93.6995L98.2323 56.0161C99.2772 52.3891 97.1839 48.6018 93.5569 47.557Z"
					fill="#6018C8"
				/>
				<path
					d="M36.0977 52.0272C35.9576 52.6265 36.0744 53.5762 36.5803 54.0666C37.0318 54.5102 37.4288 54.7671 37.3354 55.094C37.242 55.421 35.4205 55.818 33.996 54.557C32.5716 53.2959 32.6961 52.2451 32.6961 51.8014C34.79 51.2176 36.0977 52.0272 36.0977 52.0272Z"
					fill="#FF48D8"
				/>
				<path
					d="M38.3319 51.5211C38.8612 50.5014 40.8928 49.3883 41.6712 47.31C42.6987 44.5856 42.6364 39.238 35.732 37.3465C28.8276 35.455 26.4068 40.3122 25.823 42.445C25.0446 45.3017 26.7415 47.3567 26.3679 48.3531C27.7067 50.1667 33.7549 53.5138 38.3319 51.5134V51.5211Z"
					fill="#FF48D8"
				/>
				<path
					d="M27.9636 49.8008C27.7768 50.3846 27.193 51.1474 26.5158 51.3109C25.9009 51.4588 25.4338 51.4821 25.3404 51.8169C25.247 52.1516 26.6248 53.4126 28.4852 53.0545C30.3455 52.6964 30.7814 51.7234 30.9994 51.342C29.4971 49.7775 27.9558 49.8008 27.9558 49.8008H27.9636Z"
					fill="#FF48D8"
				/>
				<path
					d="M33.7862 51.8239C33.7862 51.8239 33.3347 52.9525 32.9533 53.5753C32.5719 54.198 32.0193 55.28 30.8361 54.953C29.6529 54.6261 29.7385 53.2561 29.7852 52.7579C29.832 52.2286 30.12 50.6796 30.12 50.6796C30.12 50.6796 32.3851 49.5354 33.7862 51.8161V51.8239Z"
					fill="#FF48D8"
				/>
				<path
					d="M38.2931 51.4971C37.8027 52.6803 39.0404 53.0695 38.7835 53.7545C38.5266 54.4395 35.919 53.7233 35.5921 52.151C35.2651 50.5786 38.2931 51.4971 38.2931 51.4971Z"
					fill="#FF48D8"
				/>
				<path
					d="M26.4146 48.252C26.2355 49.5207 24.9745 49.225 24.8422 49.9411C24.7177 50.6572 27.3253 51.3733 28.4073 50.1824C29.4892 48.9914 26.4146 48.252 26.4146 48.252Z"
					fill="#FF48D8"
				/>
				<path
					d="M30.2058 47.4818C31.2805 47.4818 32.1518 46.6106 32.1518 45.5358C32.1518 44.4611 31.2805 43.5898 30.2058 43.5898C29.131 43.5898 28.2598 44.4611 28.2598 45.5358C28.2598 46.6106 29.131 47.4818 30.2058 47.4818Z"
					fill="white"
				/>
				<path
					d="M30.2528 47.2322C31.0094 47.2322 31.6228 46.6188 31.6228 45.8622C31.6228 45.1056 31.0094 44.4922 30.2528 44.4922C29.4962 44.4922 28.8828 45.1056 28.8828 45.8622C28.8828 46.6188 29.4962 47.2322 30.2528 47.2322Z"
					fill="url(#paint0_linear_0_8392)"
				/>
				<path
					d="M29.6766 45.2633C29.8486 45.2633 29.988 45.1239 29.988 44.952C29.988 44.78 29.8486 44.6406 29.6766 44.6406C29.5046 44.6406 29.3652 44.78 29.3652 44.952C29.3652 45.1239 29.5046 45.2633 29.6766 45.2633Z"
					fill="white"
				/>
				<path
					d="M36.0901 48.9361C37.0832 48.9361 37.8882 48.131 37.8882 47.1379C37.8882 46.1449 37.0832 45.3398 36.0901 45.3398C35.097 45.3398 34.292 46.1449 34.292 47.1379C34.292 48.131 35.097 48.9361 36.0901 48.9361Z"
					fill="white"
				/>
				<path
					d="M36.0048 48.7889C36.7571 48.7889 37.367 48.179 37.367 47.4267C37.367 46.6743 36.7571 46.0645 36.0048 46.0645C35.2525 46.0645 34.6426 46.6743 34.6426 47.4267C34.6426 48.179 35.2525 48.7889 36.0048 48.7889Z"
					fill="url(#paint1_linear_0_8392)"
				/>
				<path
					d="M35.5369 46.7946C35.7089 46.7946 35.8483 46.6552 35.8483 46.4832C35.8483 46.3113 35.7089 46.1719 35.5369 46.1719C35.365 46.1719 35.2256 46.3113 35.2256 46.4832C35.2256 46.6552 35.365 46.7946 35.5369 46.7946Z"
					fill="white"
				/>
				<path
					d="M38.9081 44.1886C38.947 44.2197 38.9782 44.2508 39.0171 44.282C39.0482 44.3131 39.0871 44.3442 39.1183 44.3832C39.1494 44.4143 39.1883 44.4532 39.2195 44.4921C39.2506 44.531 39.2818 44.5778 39.3129 44.6478C39.344 44.7179 39.3129 44.7957 39.235 44.8268C39.1883 44.8502 39.1339 44.8346 39.0949 44.8035L39.0482 44.7646C39.0482 44.7646 39.0171 44.7334 38.986 44.7179C38.9548 44.6945 38.9237 44.6789 38.8925 44.6556C38.8614 44.64 38.8225 44.6167 38.7914 44.6011C38.7602 44.5855 38.7213 44.5622 38.6824 44.5544C38.5734 44.5077 38.5189 44.3832 38.5656 44.2742C38.6123 44.1652 38.7369 44.1107 38.8458 44.1574C38.8614 44.1574 38.877 44.173 38.8925 44.1886H38.9081Z"
					fill="#100030"
				/>
				<path
					d="M29.4118 42.235C29.4118 42.235 29.334 42.2194 29.2951 42.2116C29.2562 42.2116 29.2172 42.2039 29.1783 42.1961C29.1394 42.1961 29.1005 42.1961 29.0616 42.1961C29.0226 42.1961 28.9915 42.1961 28.9682 42.1961H28.9292C28.8747 42.2116 28.8125 42.1727 28.8047 42.1182C28.8047 42.0793 28.8125 42.0404 28.8436 42.017C28.8981 41.9703 28.9448 41.9548 28.9915 41.9314C29.0382 41.9081 29.0849 41.8925 29.1316 41.8769C29.1783 41.8614 29.225 41.8458 29.2717 41.838C29.3184 41.8224 29.3573 41.8147 29.4118 41.8069H29.4274C29.5442 41.7835 29.6609 41.8614 29.6843 41.9781C29.7076 42.0949 29.6298 42.2116 29.513 42.235C29.4819 42.235 29.4508 42.235 29.4274 42.235H29.4118Z"
					fill="#100030"
				/>
				<path
					d="M31.677 49.1012C31.7004 49.1401 31.7237 49.1634 31.7471 49.1946C31.7782 49.2257 31.8171 49.2568 31.8638 49.288C31.9572 49.3425 32.0662 49.397 32.183 49.4203C32.2997 49.4514 32.4321 49.4592 32.5488 49.4437C32.6111 49.4437 32.6656 49.4281 32.7123 49.4203L32.7668 49.4047C32.7668 49.4047 32.7901 49.4047 32.8135 49.397L33.0548 49.3503C33.1326 49.3347 33.2105 49.3892 33.226 49.467C33.226 49.4826 33.226 49.4981 33.226 49.5137C33.2182 49.5916 33.1871 49.6694 33.1482 49.7317C33.1404 49.7472 33.1248 49.7628 33.117 49.7784L33.0859 49.8173C33.0859 49.8173 33.0392 49.864 33.0159 49.8873C32.9147 49.9652 32.8057 50.0197 32.7045 50.0508C32.4865 50.1209 32.2686 50.1287 32.0506 50.0742C31.8327 50.0275 31.6225 49.9185 31.4591 49.7395C31.3812 49.6538 31.3112 49.5449 31.2722 49.4125C31.2333 49.288 31.2333 49.1323 31.3034 49.0078C31.3501 48.9144 31.4668 48.8754 31.5525 48.9221C31.5914 48.9377 31.6225 48.9688 31.6381 49.0078L31.6926 49.1089L31.677 49.1012Z"
					fill="#100030"
				/>
				<path
					opacity="0.4"
					d="M82.4588 98.1327C82.4588 98.1327 30.5707 68.1643 14.9326 54.013L15.9757 50.409L77.4614 50.1055C81.587 50.1055 84.9341 53.4526 84.9341 57.5781V96.2957C84.9341 96.2957 85.0586 97.5567 82.4665 98.1483L82.4588 98.1327Z"
					fill="#180048"
				/>
				<path
					d="M75.6243 52.7207H8.682C4.9075 52.7207 1.84766 55.7805 1.84766 59.555V98.7708C1.84766 102.545 4.9075 105.605 8.682 105.605H75.6243C79.3988 105.605 82.4587 102.545 82.4587 98.7708V59.555C82.4587 55.7805 79.3988 52.7207 75.6243 52.7207Z"
					fill="#71E3FF"
				/>
				<path
					d="M82.4587 66.1016H1.84766V78.159H82.4587V66.1016Z"
					fill="#6675F6"
				/>
				<path
					d="M74.0601 84.1523H59.0058C57.527 84.1523 56.3281 85.3512 56.3281 86.83V96.0852C56.3281 97.564 57.527 98.7629 59.0058 98.7629H74.0601C75.5389 98.7629 76.7378 97.564 76.7378 96.0852V86.83C76.7378 85.3512 75.5389 84.1523 74.0601 84.1523Z"
					fill="#F0FFFF"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_0_8392"
					x1="31.5028"
					y1="46.2065"
					x2="28.8972"
					y2="45.4947"
					gradientUnits="userSpaceOnUse"
				>
					<stop />
					<stop offset="0.45" stopColor="#100030" />
					<stop offset="0.84" stopColor="#180070" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_0_8392"
					x1="34.7032"
					y1="47.0728"
					x2="37.2562"
					y2="47.7702"
					gradientUnits="userSpaceOnUse"
				>
					<stop />
					<stop offset="0.45" stopColor="#100030" />
					<stop offset="0.84" stopColor="#180070" />
					<stop offset="1" />
				</linearGradient>
				<clipPath id="clip0_0_8392">
					<rect
						width="109.264"
						height="89.6716"
						fill="white"
						transform="translate(47.374 0.427734)"
					/>
				</clipPath>
				<clipPath id="clip1_0_8392">
					<rect
						width="99.6351"
						height="99.6351"
						fill="white"
						transform="translate(0.361328 17.3652)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export { WalletIcon };
