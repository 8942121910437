import { Grid } from '@krakentech/coral';
import React, { FC } from 'react';

import { HomePageSectionHeading } from '@/components/pages/home/HomePageSectionHeading';
import { DocumentIcon } from '@/components/svgs/DocumentIcon';
import { LaptopClickButton } from '@/components/svgs/LaptopClickButton';
import { MobilePhone } from '@/components/svgs/MobilePhone';
import { WalletIcon } from '@/components/svgs/WalletIcon';

export const HomepageSectionKantan: FC = () => (
	<section className="mt-24 space-y-2 text-base md:mt-40 md:space-y-5 md:text-lg">
		<HomePageSectionHeading
			headingText="オンラインでカンタン申し込み"
			subheadingText="４ステップのシンプルなお手続き"
		/>
		<Grid
			templateColumns="repeat(1,1fr)"
			lg={{ templateColumns: 'repeat(2, 1fr)' }}
			gap="sm"
		>
			<Grid templateColumns="repeat(2, 1fr)" gap="md">
				<div className="flex flex-col items-center">
					<div className="h-48 w-32 md:w-36 lg:w-48">
						<LaptopClickButton width="100%" height="100%" />
					</div>
					<p>① 見積金額を参考に、料金プランを選択</p>
				</div>
				<div className="flex flex-col items-center">
					<div className="h-48 w-32 md:w-44 lg:w-48">
						<MobilePhone width="100%" height="100%" />
					</div>
					<p>② お客さま情報の入力</p>
				</div>
			</Grid>
			<Grid templateColumns="repeat(2, 1fr)" gap="md">
				<div className="flex flex-col items-center">
					<div className="h-48 w-28 md:w-36 lg:w-48">
						<WalletIcon width="100%" height="100%" />
					</div>
					<p>③お支払い方法の登録</p>
				</div>
				<div className="flex flex-col items-center">
					<div className="h-48 w-28 md:w-36 lg:w-36">
						<DocumentIcon height="100%" width="100%" />
					</div>
					<p>④契約内容を確認して送信</p>
				</div>
			</Grid>
		</Grid>
	</section>
);
