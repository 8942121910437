import { FC } from 'react';

const DocumentIcon: FC<{ height?: string; width?: string }> = ({
	height = 132,
	width = 131,
}) => {
	return (
		<svg
			aria-labelledby="document-4steps"
			width={width}
			height={height}
			viewBox="0 0 132 131"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="document-4steps" lang="ja">
				書類のイメージ {/** Image of the document */}
			</title>

			<path
				d="M14.0093 31.4603V112.24C14.0093 114.758 16.0459 116.795 18.5636 116.795H94.3286C96.8463 116.795 98.8829 114.758 98.8829 112.24V13.3762C98.8829 10.8687 96.8565 8.82187 94.3389 8.82187L36.9445 8.61719L36.1769 32.8931L13.999 31.4603H14.0093Z"
				fill="#939393"
			/>
			<path
				d="M6.91648 25.1259V105.906C6.91648 108.423 8.95312 110.46 11.4708 110.46H87.2359C89.7535 110.46 91.7902 108.423 91.7902 105.906V7.05195C91.7902 4.54453 89.7637 2.49766 87.2461 2.49766L29.8517 2.29297L29.0841 26.5689L6.90625 25.1361L6.91648 25.1259Z"
				fill="#EAEAEA"
			/>
			<path
				d="M7.93019 22.2396L27.0992 3.35718C29.4326 1.06468 33.3728 2.71241 33.3728 5.98741V24.2251C33.3728 26.2105 31.7968 27.848 29.8113 27.9094L10.6423 28.5542C7.28542 28.6668 5.53535 24.6037 7.93019 22.2396Z"
				fill="#B5B5B5"
			/>
			<path
				d="M81.8328 42.3295H16.8855C15.8211 42.3295 14.9512 41.4698 14.9512 40.3952C14.9512 39.3206 15.8109 38.4609 16.8855 38.4609H81.8328C82.8972 38.4609 83.7671 39.3206 83.7671 40.3952C83.7671 41.4698 82.9074 42.3295 81.8328 42.3295Z"
				fill="#100030"
			/>
			<path
				d="M81.8328 54.1498H16.8855C15.8211 54.1498 14.9512 53.2902 14.9512 52.2155C14.9512 51.1409 15.8109 50.2812 16.8855 50.2812H81.8328C82.8972 50.2812 83.7671 51.1409 83.7671 52.2155C83.7671 53.2902 82.9074 54.1498 81.8328 54.1498Z"
				fill="#100030"
			/>
			<path
				d="M61.4459 65.9604H16.2919C15.555 65.9604 14.9512 65.1007 14.9512 64.0261C14.9512 62.9515 15.555 62.0918 16.2919 62.0918H61.4459C62.1828 62.0918 62.7866 62.9515 62.7866 64.0261C62.7866 65.1007 62.1828 65.9604 61.4459 65.9604Z"
				fill="#100030"
			/>
			<path
				d="M61.4459 78.2006H16.2919C15.555 78.2006 14.9512 77.3409 14.9512 76.2663C14.9512 75.1917 15.555 74.332 16.2919 74.332H61.4459C62.1828 74.332 62.7866 75.1917 62.7866 76.2663C62.7866 77.3409 62.1828 78.2006 61.4459 78.2006Z"
				fill="#100030"
			/>
			<path
				d="M36.5457 90.4506H15.5755C15.2275 90.4506 14.9512 89.5909 14.9512 88.5163C14.9512 87.4417 15.2275 86.582 15.5755 86.582H36.5457C36.8937 86.582 37.17 87.4417 37.17 88.5163C37.17 89.5909 36.8937 90.4506 36.5457 90.4506Z"
				fill="#100030"
			/>
			<path
				d="M95.0247 121.676C106.533 121.676 115.862 112.347 115.862 100.839C115.862 89.3311 106.533 80.002 95.0247 80.002C83.5166 80.002 74.1875 89.3311 74.1875 100.839C74.1875 112.347 83.5166 121.676 95.0247 121.676Z"
				fill="#18F0B8"
			/>
			<path
				d="M77.9126 24C77.9331 24.655 78.322 25.5966 78.977 25.965C79.5706 26.2925 80.0414 26.446 80.0414 26.8145C80.0414 27.1829 78.2708 28.104 76.4491 27.2034C74.6274 26.3028 74.4636 25.1872 74.3408 24.7267C76.3263 23.5395 77.9024 24 77.9024 24H77.9126Z"
				fill="#FF48D8"
			/>
			<path
				d="M80.0722 22.853C80.328 21.6555 82.1088 19.9362 82.3237 17.5823C82.6103 14.4915 81.0546 9.00586 73.4198 9.00586C65.785 9.00586 64.6592 14.6757 64.6592 17.0398C64.6592 20.2023 66.9824 21.8398 66.88 22.9655C68.7734 24.4598 75.9375 26.1996 80.0824 22.853H80.0722Z"
				fill="#FF48D8"
			/>
			<path
				d="M68.9168 24C68.8964 24.655 68.5075 25.5966 67.8525 25.965C67.2589 26.2925 66.7881 26.446 66.7881 26.8145C66.7881 27.1829 68.5586 28.104 70.3804 27.2034C72.2021 26.3028 72.3658 25.1872 72.4886 24.7267C70.5032 23.5395 68.9271 24 68.9271 24H68.9168Z"
				fill="#FF48D8"
			/>
			<path
				d="M75.477 24.4486C75.477 24.4486 75.3338 25.7279 75.1086 26.4852C74.8834 27.2426 74.6276 28.5014 73.3176 28.5014C72.0076 28.5014 71.7108 27.0583 71.6187 26.5364C71.5266 25.9735 71.373 24.3053 71.373 24.3053C71.373 24.3053 73.3892 22.4938 75.4668 24.4486H75.477Z"
				fill="#FF48D8"
			/>
			<path
				d="M80.0208 22.8429C79.8469 24.204 81.2285 24.245 81.1569 25.0228C81.0852 25.8006 78.1991 25.8006 77.4213 24.2757C76.6435 22.7508 80.0106 22.8429 80.0106 22.8429H80.0208Z"
				fill="#FF48D8"
			/>
			<path
				d="M66.8907 22.8429C67.0647 24.204 65.683 24.245 65.7546 25.0228C65.8263 25.8006 68.7124 25.8006 69.4902 24.2757C70.268 22.7508 66.9009 22.8429 66.9009 22.8429H66.8907Z"
				fill="#FF48D8"
			/>
			<path
				d="M70.0219 21.0614C71.1693 21.0614 72.0995 20.1312 72.0995 18.9838C72.0995 17.8364 71.1693 16.9062 70.0219 16.9062C68.8745 16.9062 67.9443 17.8364 67.9443 18.9838C67.9443 20.1312 68.8745 21.0614 70.0219 21.0614Z"
				fill="white"
			/>
			<path
				d="M70.1657 20.7747C70.9739 20.7747 71.6292 20.1194 71.6292 19.3112C71.6292 18.5029 70.9739 17.8477 70.1657 17.8477C69.3574 17.8477 68.7021 18.5029 68.7021 19.3112C68.7021 20.1194 69.3574 20.7747 70.1657 20.7747Z"
				fill="url(#paint0_linear_0_8413)"
			/>
			<path
				d="M69.3158 18.8601C69.4967 18.8601 69.6433 18.7135 69.6433 18.5326C69.6433 18.3517 69.4967 18.2051 69.3158 18.2051C69.1349 18.2051 68.9883 18.3517 68.9883 18.5326C68.9883 18.7135 69.1349 18.8601 69.3158 18.8601Z"
				fill="white"
			/>
			<path
				d="M76.531 20.898C77.588 20.898 78.4448 20.0411 78.4448 18.9841C78.4448 17.9272 77.588 17.0703 76.531 17.0703C75.474 17.0703 74.6172 17.9272 74.6172 18.9841C74.6172 20.0411 75.474 20.898 76.531 20.898Z"
				fill="white"
			/>
			<path
				d="M76.5314 20.7542C77.334 20.7542 77.9847 20.1036 77.9847 19.3009C77.9847 18.4983 77.334 17.8477 76.5314 17.8477C75.7288 17.8477 75.0781 18.4983 75.0781 19.3009C75.0781 20.1036 75.7288 20.7542 76.5314 20.7542Z"
				fill="url(#paint1_linear_0_8413)"
			/>
			<path
				d="M75.7733 18.8005C75.9598 18.8005 76.111 18.6493 76.111 18.4627C76.111 18.2762 75.9598 18.125 75.7733 18.125C75.5868 18.125 75.4355 18.2762 75.4355 18.4627C75.4355 18.6493 75.5868 18.8005 75.7733 18.8005Z"
				fill="white"
			/>
			<path
				d="M78.5984 15.1459C78.5984 15.1459 78.6905 15.1868 78.7417 15.2073C78.7827 15.2278 78.8338 15.2585 78.8748 15.279C78.9157 15.2994 78.9669 15.3301 79.0078 15.3608C79.0488 15.3915 79.0999 15.4325 79.1511 15.4939C79.2023 15.5553 79.192 15.6474 79.1204 15.6986C79.0795 15.7293 79.0181 15.7395 78.9771 15.719L78.9157 15.6986C78.9157 15.6986 78.8748 15.6781 78.8338 15.6679C78.8031 15.6576 78.7622 15.6474 78.7213 15.6372C78.6803 15.6269 78.6394 15.6167 78.5984 15.6064C78.5575 15.6064 78.5166 15.586 78.4756 15.586C78.3528 15.5655 78.2607 15.4529 78.2812 15.3301C78.3016 15.2073 78.4142 15.1152 78.537 15.1357C78.5575 15.1357 78.578 15.1357 78.5984 15.1561V15.1459Z"
				fill="#100030"
			/>
			<path
				d="M68.2826 15.8116C68.2826 15.8116 68.2007 15.8116 68.1597 15.8218C68.1188 15.8218 68.0779 15.8321 68.0369 15.8423C67.996 15.8423 67.9551 15.8628 67.9141 15.873C67.8732 15.8832 67.8425 15.8935 67.822 15.9037L67.7913 15.9242C67.7299 15.9549 67.6685 15.9242 67.6378 15.873C67.6173 15.8321 67.6276 15.7911 67.6378 15.7604C67.6787 15.699 67.7197 15.6683 67.7606 15.6274C67.8015 15.5967 67.8425 15.566 67.8834 15.5353C67.9244 15.5046 67.9653 15.4841 68.0165 15.4534C68.0574 15.4329 68.0983 15.4022 68.1495 15.3817H68.1597C68.2723 15.3203 68.4156 15.3715 68.4668 15.4841C68.518 15.5967 68.4668 15.7399 68.3542 15.7911C68.3235 15.8013 68.2928 15.8116 68.2621 15.8116H68.2826Z"
				fill="#100030"
			/>
			<path
				d="M72.5398 22.239C72.5398 22.239 72.6012 22.2901 72.6421 22.3209C72.6831 22.3413 72.7343 22.3618 72.7854 22.3823C72.898 22.413 73.0208 22.4334 73.1539 22.4334C73.2767 22.4334 73.42 22.4027 73.5428 22.3516C73.6042 22.3311 73.6554 22.3004 73.7065 22.2799L73.7577 22.2492L73.7986 22.2287L74.034 22.1162C74.1057 22.0752 74.1978 22.1162 74.2387 22.1878C74.2387 22.198 74.2489 22.2185 74.2489 22.239C74.2592 22.3209 74.2489 22.413 74.2285 22.4846C74.2285 22.5051 74.2182 22.5255 74.208 22.5358L74.1875 22.5869C74.1875 22.5869 74.1466 22.6484 74.1364 22.6791C74.0545 22.7916 73.9624 22.8735 73.86 22.9349C73.6554 23.068 73.4302 23.1396 73.1948 23.1498C72.9594 23.1601 72.7138 23.1089 72.4989 22.9656C72.3863 22.894 72.2942 22.8019 72.2123 22.6791C72.1407 22.5562 72.0997 22.4027 72.1304 22.2492C72.1509 22.1366 72.2635 22.0752 72.3658 22.0957C72.4068 22.0957 72.4477 22.1264 72.4784 22.1571L72.5603 22.2492L72.5398 22.239Z"
				fill="#100030"
			/>
			<path
				d="M92.9895 112.438L83.2259 102.91C82.1104 101.825 82.0899 100.044 83.1748 98.9287C84.2596 97.8131 86.0404 97.7927 87.156 98.8775L92.8565 104.445L104.851 91.9488C105.926 90.8333 107.707 90.7923 108.822 91.867C109.938 92.9416 109.979 94.7223 108.904 95.8379L92.9793 112.428L92.9895 112.438Z"
				fill="#100030"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_0_8413"
					x1="71.5473"
					y1="19.3112"
					x2="68.6612"
					y2="19.3112"
					gradientUnits="userSpaceOnUse"
				>
					<stop />
					<stop offset="0.45" stopColor="#100030" />
					<stop offset="0.84" stopColor="#180070" />
					<stop offset="1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_0_8413"
					x1="75.0781"
					y1="19.3009"
					x2="77.9028"
					y2="19.3009"
					gradientUnits="userSpaceOnUse"
				>
					<stop />
					<stop offset="0.45" stopColor="#100030" />
					<stop offset="0.84" stopColor="#180070" />
					<stop offset="1" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export { DocumentIcon };
