import { FC } from 'react';

const NikkeiLogo: FC = () => {
	return (
		<svg
			aria-labelledby="nikkei-logo"
			width="178"
			height="39"
			viewBox="0 0 178 39"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="nikkei-logo" lang="ja">
				日経のロゴ {/* Nikkei logo */}
			</title>
			<g clipPath="url(#clip0_822_1212)">
				<path
					d="M36.1192 0L29.5183 25.2649L21.3785 0H10.1886L0 38.9905H9.29865L16.3723 11.9101L25.0498 38.9905H35.2199L45.4085 0H36.1192ZM168.711 0L158.522 38.9905H167.811L178 0H168.711ZM50.971 0L40.7731 38.9905H50.0625L60.2604 0H50.971ZM154.943 22.9837L156.835 15.7312H141.009L143.049 7.92737H160.775L162.851 0H135.836L125.87 38.116L118.537 18.0409H115.654L133.055 0H122.097L105.864 16.5486L110.184 0H100.894L90.8727 38.3346L83.4653 18.0409H80.5821L97.9742 0H87.016L70.7828 16.5486L75.1123 0H65.8229L55.625 38.9905H64.9144L70.3934 18.0409H73.6753L80.3596 38.9905H99.9859L105.465 18.0409H108.747L115.431 38.9905H152.913L154.981 31.0821H136.995L139.109 22.9837H154.943Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_822_1212">
					<rect width="178" height="39" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export { NikkeiLogo };
