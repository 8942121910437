import { FC } from 'react';

const LaptopClickButton: FC<{ height?: string; width?: string }> = ({
	height = 57,
	width = 101,
}) => {
	return (
		<svg
			aria-labelledby="laptop-click-button"
			width={width}
			height={height}
			viewBox="0 0 101 57"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="laptop-click-button" lang="ja">
				クリックしているボタンが表示されているパソコンの画面のイラスト
				{/* Illustration of the computer screen showing the button you are clicking on. */}
			</title>
			<path
				d="M89.1831 2.55431V51.4268C89.1831 52.7891 88.0769 53.9811 86.6303 53.9811H14.4701C13.1086 53.9811 11.9172 52.8742 11.9172 51.4268V2.55431C11.9172 1.19201 13.0235 0 14.4701 0H86.6303C87.9918 0.0851437 89.1831 1.19201 89.1831 2.55431Z"
				fill="#5840FF"
			/>
			<path
				d="M86.4604 2.72461H14.5554V48.4468H86.4604V2.72461Z"
				fill="#180048"
			/>
			<path
				opacity="0.5"
				d="M89.1831 50.2346V51.4266C89.1831 52.7889 88.0769 53.9809 86.6303 53.9809H14.4701C13.1086 53.9809 11.9172 52.8741 11.9172 51.4266V50.2346H89.1831Z"
				fill="#2D1A83"
			/>
			<path
				d="M95.5652 51.7673V54.1514C95.5652 54.3216 95.5652 54.4919 95.4801 54.6622C95.2248 55.5137 94.459 56.1948 93.5229 56.1948H6.98172C6.04568 56.1948 5.27983 55.5988 5.02455 54.6622C4.93946 54.4919 4.93945 54.3216 4.93945 54.1514V51.7673H95.5652Z"
				fill="#5840FF"
			/>
			<path
				opacity="0.5"
				d="M95.48 54.6621C95.2247 55.5135 94.4588 56.1947 93.5228 56.1947H6.98159C6.04555 56.1947 5.2797 55.5987 5.02441 54.6621H95.48Z"
				fill="#2D1A83"
			/>
			<path
				d="M59.5705 51.7673V52.0228C59.5705 52.3633 59.3153 52.6188 58.9749 52.6188H41.5305C41.1901 52.6188 40.9348 52.3633 40.9348 52.0228V51.7673H59.5705Z"
				fill="#6675F6"
			/>
			<path
				d="M70.2071 35.0794H30.2977C28.3405 35.0794 26.8088 33.5468 26.8088 31.5885V25.1176C26.8088 23.1593 28.3405 21.6267 30.2977 21.6267H70.2071C72.1643 21.6267 73.696 23.1593 73.696 25.1176V31.5885C73.696 33.4617 72.0792 35.0794 70.2071 35.0794Z"
				fill="#100030"
			/>
			<path
				d="M73.6959 23.585V31.8439C73.6959 33.2914 72.5045 34.4834 71.0579 34.4834H29.3616C27.915 34.4834 26.7236 33.2914 26.7236 31.8439V23.585C26.7236 22.1376 27.915 20.9456 29.3616 20.9456H71.0579C71.7387 20.9456 72.3343 21.201 72.7598 21.6267C73.3555 22.1376 73.6959 22.8187 73.6959 23.585Z"
				fill="#F050F8"
			/>
			<path
				d="M48.2527 18.1357C47.5719 17.3694 46.8061 16.6031 46.1253 15.8368C45.87 15.5814 45.4445 15.5814 45.1893 15.8368C44.934 16.0923 44.934 16.518 45.1893 16.7734C45.9551 17.5397 46.6359 18.306 47.3166 19.0723C47.5719 19.3277 47.9974 19.3277 48.2527 19.0723C48.4229 18.7317 48.4229 18.3911 48.2527 18.1357Z"
				fill="#18F0B8"
			/>
			<path
				d="M51.1459 18.391C51.1459 17.5396 51.1459 16.6882 51.1459 15.9219C51.1459 15.5813 50.8907 15.2407 50.4652 15.2407C50.1248 15.2407 49.8695 15.4962 49.7844 15.9219C49.7844 16.7733 49.7844 17.6247 49.7844 18.391C49.7844 18.7316 50.0397 19.0722 50.4652 19.0722C50.8056 18.987 51.1459 18.7316 51.1459 18.391Z"
				fill="#18F0B8"
			/>
			<path
				d="M53.6133 19.0722C54.2941 18.3911 55.06 17.7099 55.7407 17.0288C55.996 16.7734 55.996 16.3476 55.7407 16.0922C55.4854 15.8368 55.06 15.8368 54.8047 16.0922C54.1239 16.7734 53.3581 17.4545 52.6773 18.1357C52.422 18.3911 52.422 18.8168 52.6773 19.0722C52.9326 19.3277 53.3581 19.3277 53.6133 19.0722Z"
				fill="#18F0B8"
			/>
			<path
				d="M52.5074 37.5486C53.1881 38.3149 53.954 39.0811 54.6347 39.8474C54.89 40.1029 55.3155 40.1029 55.5708 39.8474C55.8261 39.592 55.8261 39.1663 55.5708 38.9109C54.8049 38.1446 54.1242 37.3783 53.4434 36.612C53.1881 36.3566 52.7627 36.3566 52.5074 36.612C52.2521 36.8674 52.2521 37.2931 52.5074 37.5486Z"
				fill="#18F0B8"
			/>
			<path
				d="M49.699 37.4634C49.699 38.3148 49.699 39.1663 49.699 39.9325C49.699 40.2731 49.9543 40.6137 50.3797 40.6137C50.7201 40.6137 50.9754 40.3583 51.0605 39.9325C51.0605 39.0811 51.0605 38.2297 51.0605 37.4634C51.0605 37.1228 50.8052 36.7822 50.3797 36.7822C50.0394 36.7822 49.699 37.0377 49.699 37.4634Z"
				fill="#18F0B8"
			/>
			<path
				d="M47.4016 36.5267C46.7208 37.2078 45.955 37.889 45.2742 38.5701C45.0189 38.8256 45.0189 39.2513 45.2742 39.5067C45.5295 39.7622 45.955 39.7622 46.2103 39.5067C46.891 38.8256 47.6569 38.1444 48.3376 37.4633C48.5929 37.2078 48.5929 36.7821 48.3376 36.5267C48.0823 36.3564 47.6569 36.2713 47.4016 36.5267Z"
				fill="#18F0B8"
			/>
			<path
				d="M26.8088 31.6733V23.4144C26.8088 21.9669 28.0002 20.7749 29.4468 20.7749H71.1431C72.5897 20.7749 73.7811 21.9669 73.7811 23.4144V23.6698C73.0152 22.0521 70.8878 22.3075 68.2499 22.2223C65.6971 22.1372 42.7215 22.1372 35.4034 22.2223C28.1704 22.3075 28.0002 20.6898 27.83 25.9687C27.7449 29.119 27.7449 31.8436 27.6598 33.5465C27.1492 33.1207 26.8088 32.4396 26.8088 31.6733Z"
				fill="#F484FA"
			/>
			<path
				opacity="0.2"
				d="M71.7385 39.9323L73.4404 41.8906C73.5255 42.0609 73.7807 42.0609 73.8658 41.8906L75.1423 40.7837C75.3125 40.6986 75.3125 40.4432 75.1423 40.2729L73.4404 38.57C73.2702 38.3997 73.3553 38.2294 73.4404 38.0591L75.4826 36.2711C75.6528 36.1008 75.6528 35.8454 75.3975 35.7603L66.3775 31.0774C66.1222 30.9071 65.8669 31.1625 65.8669 31.4179L68.3347 41.55C68.4198 41.8055 68.6751 41.8906 68.8453 41.7203L71.0577 39.8472C71.4832 39.762 71.6534 39.762 71.7385 39.9323Z"
				fill="#100030"
			/>
			<path
				d="M72.0789 39.4217L73.7808 41.38C73.8659 41.5503 74.1212 41.5503 74.2063 41.38L75.4827 40.2731C75.6529 40.188 75.6529 39.9325 75.4827 39.7623L73.7808 38.0594C73.6106 37.8891 73.6957 37.7188 73.7808 37.5485L75.9082 35.8456C76.0784 35.6754 76.0784 35.4199 75.8231 35.3348L66.8031 30.737C66.5478 30.5667 66.2925 30.8222 66.2925 31.0776L68.7602 41.2097C68.8453 41.4651 69.1006 41.5503 69.2708 41.38L71.4833 39.5068C71.7385 39.3365 71.9938 39.3365 72.0789 39.4217Z"
				fill="#FFA26B"
			/>
			<path
				d="M2.2869 9.50306L2.28027 1.61114L2.30171 1.60474L4.40219 6.01165L7.44843 4.75409L7.98062 13.0092L7.95778 13.0162L5.33148 8.24706L2.2869 9.50306Z"
				fill="#FFA26B"
			/>
			<path
				d="M1.14472 17.1577L3.70788 14.1597L3.7181 14.1642L3.08279 16.5213L4.64804 17.0361L2.16632 20.3433L2.15538 20.3385L2.70884 17.6726L1.14472 17.1577Z"
				fill="#F050F8"
			/>
			<path
				d="M95.1958 8.67715L99.4952 5.02808L99.5093 5.03537L98.1408 8.07437L100.326 8.94829L96.0873 13.0161L96.0723 13.0084L97.3794 9.55082L95.1958 8.67715Z"
				fill="#18F0B8"
			/>
		</svg>
	);
};

export { LaptopClickButton };
